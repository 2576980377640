.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 100%;
}

input {
  flex: 1;
  padding: 10px;
}
